import { IIconType } from "../components/Icons";
import { advbTheme } from "./themes/advb";
import { allobankTheme } from "./themes/allobank";
import { ambevTheme } from "./themes/ambev";
import { ativoTheme } from "./themes/ativo";
import { auddasTheme } from "./themes/auddas";
import { avancardTheme } from "./themes/avancard";
import { avusTheme } from "./themes/avus";
import { b4nkTheme } from "./themes/b4nk";
import { bbnkTheme } from "./themes/bbnk";
import { bdlTheme } from "./themes/bdl";
import { bdsTheme } from "./themes/bds";
import { campoBankTheme } from "./themes/campo";
import { cariocaTheme } from "./themes/carioca";
import { cartaodetodosTheme } from "./themes/cartaodetodos";
import { ciaTheme } from "./themes/cia";
import { contaeduTheme } from "./themes/contaedu";
import { coxaTheme } from "./themes/coxa";
import { cubosTheme } from "./themes/cubos";
import { dafitiTheme } from "./themes/dafiti";
import { dasbnkTheme } from "./themes/dasbnk";
import { doitTheme } from "./themes/doit";
import { easyTheme } from "./themes/easy";
import { ebanxTheme } from "./themes/ebanx";
import { estacioTheme } from "./themes/estacio";
import { estudanteTheme } from "./themes/estudante";
import { exataTheme } from "./themes/exata";
import { fenatraTheme } from "./themes/fenatra";
import { figueirenseTheme } from "./themes/figueirense";
import { flowinsTheme } from "./themes/flowins";
import { fontesTheme } from "./themes/fontes";
import { fretebankTheme } from "./themes/fretebank";
import { gcdTheme } from "./themes/gcd";
import { havanTheme } from "./themes/havan";
import { hubnkTheme } from "./themes/hubnk";
import { hxmaisTheme } from "./themes/hxmais";
import { iganheTheme } from "./themes/iganhe";
import { investprevTheme } from "./themes/investprev";
import { jaboataoTheme } from "./themes/jaboatao";
import { justaTheme } from "./themes/justa";
import { konigTheme } from "./themes/konig";
import { lifesbankTheme } from "./themes/lifesbank";
import { lopesTheme } from "./themes/lopes";
import { magabankTheme } from "./themes/magabank";
import { magpayTheme } from "./themes/magpay";
import { maximaTheme } from "./themes/maxima";
import { meridianTheme } from "./themes/meridian";
import { meuhelpTheme } from "./themes/meuhelp";
import { meuhelpotbTheme } from "./themes/meuhelpotb";
import { nerdTheme } from "./themes/nerd";
import { novadaxTheme } from "./themes/novadax";
import { olxTheme } from "./themes/olx";
import { oraTheme } from "./themes/ora";
import { paggjaBankTheme } from "./themes/paggjabank";
import { partyouTheme } from "./themes/partyou";
import { pitaiaTheme } from "./themes/pitaia";
import { rappiTheme } from "./themes/rappi";
import { saporeTheme } from "./themes/sapore";
import { shopbanxTheme } from "./themes/shopbanx";
import { singuTheme } from "./themes/singu";
import { skadiTheme } from "./themes/skadi";
import { slimBankTheme } from "./themes/slimbank";
import { smartTheme } from "./themes/smart";
import { souzaCruzTheme } from "./themes/souzacruz";
import { startTheme } from "./themes/start";
import { sysdataTheme } from "./themes/sysdata";
import { tipayTheme } from "./themes/tipay";
import { tipicTheme } from "./themes/tipic";
import { vcpayTheme } from "./themes/vcpay";
import { venetoTheme } from "./themes/veneto";
import { vivipayTheme } from "./themes/vivipay";
import { wTheme } from "./themes/w";
import { whitelabelTheme } from "./themes/whitelabel";
import { wpagTheme } from "./themes/wpag";
import { xpagoTheme } from "./themes/xpago";
import { zigTheme } from "./themes/zig";
import { aragonTheme } from "./themes/aragon";
import { ceoPagTheme } from "./themes/ceopag";
import { bluBankTheme } from "./themes/blubank";
import { edanBankTheme } from "./themes/edanbank";
import { suitBankTheme } from "./themes/suitbank";
import { vantageTheme } from "./themes/vantage";
import { weNovTheme } from "./themes/wenov";
// import axios from "axios";
// import { async } from "q";

// import { async } from "q";

export interface ITheme {
	pageTitle: string;
	metaDescription: string;
	fontFamilyUrl: string;
	hibotColor: string;
	header: {
		logo: string;
		mobileLogo: string;
		primaryBtnText: string;
		secondaryBtnText: string;
	};
	mainSection: {
		title: string;
		subTitle?: string;
		description: string;
		playStoreLink: string;
		appleStoreLink: string;
		ibLink: string;
		hibotToken: string;
		hibotTemplate: string;
		hibotOrigin: string;
		backgroundImage: string;
	};
	servicesSection: {
		title: string;
		services: {
			id: string;
			title: string;
			shortTitle?: string;
			description: string;
			icon: IIconType;
		}[];
	};
	otherServicesSection: {
		title: string;
		description: string;
		services: {
			title: string;
			description: string;
			icon: IIconType;
		}[];
	};
	footer: {
		title: string;
		titleFares?: string;
		fareUrl?: string;
		btnFares?: string;
		infos: {
			icon: IIconType;
			label: string;
			telNumber?: string;
			value?: string;
			link?: string;
			newTab?: boolean;
		}[];
	};
}

const getThemeByPartnerId = () => {
	console.log("slug:", JSON.stringify(process.env.GATSBY_PARTNER_SLUG));
	switch (process.env.GATSBY_PARTNER_SLUG) {
		case "maxima":
			return maximaTheme;
		case "justa":
			return justaTheme;
		case "fontes":
			return fontesTheme;
		case "gcd":
			return gcdTheme;
		case "figueirense":
			return figueirenseTheme;
		case "meuhelp":
			return meuhelpTheme;
		case "sysdata":
			return sysdataTheme;
		case "doit":
			return doitTheme;
		case "novadax":
			return novadaxTheme;
		case "investprev":
			return investprevTheme;
		case "ambev":
			return ambevTheme;
		case "rappi":
			return rappiTheme;
		case "olx":
			return olxTheme;
		case "zig":
			return zigTheme;
		case "cubos":
			return cubosTheme;
		case "cartaodetodos":
			return cartaodetodosTheme;
		case "sapore":
			return saporeTheme;
		case "xpago":
			return xpagoTheme;
		case "pitaia":
			return pitaiaTheme;
		case "iganhe":
			return iganheTheme;
		case "carioca":
			return cariocaTheme;
		case "bds":
			return bdsTheme;
		case "jaboatao":
			return jaboataoTheme;
		case "magabank":
			return magabankTheme;
		case "b4nk":
			return b4nkTheme;
		case "wpag":
			return wpagTheme;
		case "konig":
			return konigTheme;
		case "singu":
			return singuTheme;
		case "hubnk":
			return hubnkTheme;
		case "vcpay":
			return vcpayTheme;
		case "allobank":
			return allobankTheme;
		case "estacio":
			return estacioTheme;
		case "w":
			return wTheme;
		case "fretebank":
			return fretebankTheme;
		case "havan":
			return havanTheme;
		case "whitelabel":
			return whitelabelTheme;
		case "bbnk":
			return bbnkTheme;
		case "slimbank":
			return slimBankTheme;
		case "paggjabank":
			return paggjaBankTheme;
		case "souzacruz":
			return souzaCruzTheme;
		case "veneto":
			return venetoTheme;
		case "ebanx":
			return ebanxTheme;
		case "dafiti":
			return dafitiTheme;
		case "campo":
			return campoBankTheme;
		case "coxa":
			return coxaTheme;
		case "nerd":
			return nerdTheme;
		case "lopes":
			return lopesTheme;
		case "tipay":
			return tipayTheme;
		case "estudante":
			return estudanteTheme;
		case "flowins":
			return flowinsTheme;
		case "tipic":
			return tipicTheme;
		case "ora":
			return oraTheme;
		case "magpay":
			return magpayTheme;
		case "smart":
			return smartTheme;
		case "auddas":
			return auddasTheme;
		case "contaedu":
			return contaeduTheme;
		case "easy":
			return easyTheme;
		case "dasbnk":
			return dasbnkTheme;
		case "partyou":
			return partyouTheme;
		case "cia":
			return ciaTheme;
		case "shopbanx":
			return shopbanxTheme;
		case "vivipay":
			return vivipayTheme;
		case "avus":
			return avusTheme;
		case "fenatra":
			return fenatraTheme;
		case "meuhelpotb":
			return meuhelpotbTheme;
		case "hxmais":
			return hxmaisTheme;
		case "advb":
			return advbTheme;
		case "skadi":
			return skadiTheme;
		case "avancard":
			return avancardTheme;
		case "exata":
			return exataTheme;
		case "start":
			return startTheme;
		case "ativo":
			return ativoTheme;
		case "lifesbank":
			return lifesbankTheme;
		case "bdl":
			return bdlTheme;
		case "meridian":
			return meridianTheme;
		case "aragon":
			return aragonTheme;
		case "ceopag":
			return ceoPagTheme;
		case "blubank":
			return bluBankTheme;
		case "edanbank":
			return edanBankTheme;
		case "suitbank":
			return suitBankTheme;
		case "vantage":
			return vantageTheme;
		case "wenov":
			return weNovTheme;
		default:
			// Descomentar essa linha se precisar testar localmente
			return bbnkTheme;
		// throw new Error("Partner não configurado");
	}
};

// async function getURL() {
// 	const res = await axios(apiUrl + 25);
// 	return await res.data;
// }

// getURL().then(res => console.log(res));

const theme = getThemeByPartnerId();

if (process.env.GATSBY_ENVIRONMENT === "hml") {
	theme.mainSection.ibLink = `https://hml-sandbox-internetbanking-${process.env.GATSBY_PARTNER_SLUG}.bbnk.com.br`;
} else if (process.env.GATSBY_ENVIRONMENT === "cubos-hml") {
	theme.mainSection.ibLink = `https://hml-${process.env.GATSBY_PARTNER_SLUG}-internetbanking.bbnk.cubos.io`;
} else if (process.env.GATSBY_ENVIRONMENT !== "prd") {
	theme.mainSection.ibLink = `https://${process.env.GATSBY_ENVIRONMENT}-${process.env.GATSBY_PARTNER_SLUG}-internetbanking.bbnk.cubos.io`;
}

export default theme;
