import { ITheme } from "..";

export const avancardTheme: ITheme = {
	pageTitle: "Avancard",
	metaDescription: `Chegamos para descomplicar sua relação com o dinheiro. Somos 100% digitais para você acessar sua conta e gerenciar suas finanças pelo celular, computador ou tablet, a qualquer hora e em qualquer lugar.`,
	fontFamilyUrl: "https://fonts.googleapis.com/css?family=Lato:300,400,700",
	hibotColor: "20212f",
	header: {
		logo: "avancard/logo.png",
		mobileLogo: "avancard/mobile-logo.png",
		primaryBtnText: "Abrir conta!",
		secondaryBtnText: "Entrar",
	},
	mainSection: {
		title: "",
		subTitle: "Sua conta completa",
		description: `Chegamos para descomplicar sua relação com o dinheiro. Somos 100% digitais para você acessar sua conta e gerenciar suas finanças pelo celular, computador ou tablet, a qualquer hora e em qualquer lugar.`,
		playStoreLink:
			"https://play.google.com/store/apps/details?id=br.com.bbnk.avancard",
		appleStoreLink:
			"https://apps.apple.com/br/app/avancard-banking/id1519332140",
		ibLink: "https://ibk.avancard.com.br/",
		hibotToken: "",
		hibotTemplate: "",
		hibotOrigin: "",
		backgroundImage: "avancard/main-bg.jpg",
	},
	servicesSection: {
		title: "Produtos e Serviços",
		services: [
			{
				id: "1",
				title: "Conta Pagamento",
				description:
					"É uma conta que pode ser utilizada para a realização de saques, pagamentos, transferências entre contas na mesma instituição e em outras instituições de pagamento ou instituições financeiras (TED e DOC). Abra uma conta com a gente! É fácil, rápido e seguro!",
				icon: "wallet",
			},
			{
				id: "2",
				title: "Transferências",
				description:
					"É muito prático e seguro transferir dinheiro para contas de outros bancos (via DOC e TED) ou realizar transferências internas para contas do mesmo banco.",
				icon: "arrows",
			},
			{
				id: "3",
				title: "Serviços",
				description:
					"Inovamos todos os dias para oferecer novos serviços e funcionalidades como recarga de celular e depósito por boleto. Aguarde novidades em breve!",
				icon: "services",
			},
			{
				id: "4",
				title: "Pagamentos",
				description:
					"Oferecemos os serviços digitais que vão facilitar sua vida financeira: pagamento de boletos por código de barras, agendamentos, em breve DDA (débito direto autorizado) e muito mais. Você acessa sua conta pelo celular, computador ou tablet e paga todas as suas contas de onde estiver.",

				icon: "payment",
			},
			{
				id: "5",
				title: "Cartão Compra e Saque - em breve",
				description:
					"É a forma mais simples e segura de comprar pela internet ou em qualquer estabelecimento no Brasil e no mundo. Com ele, você saca dinheiro em toda Rede Banco 24Horas e na Rede Cirrus no exterior.",
				icon: "creditCard",
			},
		],
	},
	otherServicesSection: {
		title: "Uma nova relação com seu dinheiro",
		description: "",
		services: [
			{
				title: "Inovadora",
				description:
					"Constante inovação para facilitar seu dia a dia financeiro.",
				icon: "inovation",
			},
			{
				title: "Segura",
				description: "Autenticação por biometria e senha.",
				icon: "locked",
			},
			{
				title: "Prática",
				description:
					"Gerenciamento da sua conta pelo celular, computador ou tablet.",
				icon: "pratical",
			},
			{
				title: "Simples",
				description: "Nosso aplicativo é intuitivo e fácil de usar.",
				icon: "barsCode",
			},
		],
	},
	footer: {
		title: "Nossos canais de atendimento",
		infos: [
			{
				icon: "telephone",
				label: "SAC – Serviço de Atendimento ao Cliente",
				telNumber: "0800 729 0413",
			},
			{
				icon: "telephone",
				label: "Ouvidoria",
				telNumber: "0800 729 0428",
			},
		],
	},
};
